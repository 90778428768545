import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import userRepository from '@/lib/repositories/userRepository';
import VueQRCodeComponent from 'vue-qrcode-component';

window.Vue = require('vue').default;
require('./bootstrap');

import VueRouter from 'vue-router';
import { routes } from './routes';
import store from './store';
import Vuelidate from 'vuelidate';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '../sass/app.scss';
import VueClipboard from 'vue-clipboard2';

import VueAxios from 'vue-axios';
import axios from 'axios';

Vue.prototype.$userRepository = userRepository;

Vue.use(VueAxios, axios);
window.axios = require('axios');
window.axios.defaults.withCredentials = true;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(Toast, {
	transitionDuration: 200,
	transition: 'Vue-Toastification__fade',
	position: POSITION.TOP_CENTER,
});
Vue.use(VueClipboard);
Vue.component('qr-code', VueQRCodeComponent);

export const router = new VueRouter({
	mode: 'history',
	routes: routes,
	scrollBehavior() {
		document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
	},
});

router.beforeEach(async (to, from, next) => {
	const token = localStorage.getItem('context');
	const sessionToken = sessionStorage.getItem('context');

	if (token || sessionToken) {
		if (to.name === 'AccountDeleted') {
			return store.state.userDeleted ? next() : next({ path: '/' });
		}
		await Promise.all([store.dispatch('user/getUserData'), store.dispatch('user/getUserAuthData')]);
		const registered = store.state.user.userData.user.end_registration;
		const verified = store.state.user.userAuthData.email_verified_at;
		if (to.name === 'Mail__confirmation') {
			return verified ? next({ path: '/' }) : next();
		}
		if (to.name === 'Sign_Up') {
			return registered ? next({ path: '/' }) : next();
		}
		if (!verified) {
			return next({ name: 'Mail__confirmation' });
		}
		if (!registered) {
			return next({ name: 'Sign_Up' });
		}
		if (to.name === 'Login') {
			return next({ path: '/' });
		}

		const user = store.state.user.userData.user;
		await store.dispatch('marketplace/login', {
			id: user.auth_id,
			token: user.token || token,
			deleted: user.deleted,
		});

		return next();
	} else {
		if (to.name !== 'Login' && to.name !== 'ForgotPass') {
			return next({ name: 'Login' });
		}
		return next();
	}
});

Number.prototype.lead0 = function (n) {
	var nz = '' + this;
	while (nz.length < n) {
		nz = '0' + nz;
	}
	return nz;
};
//
// import Echo from "laravel-echo"
// import VueEcho from 'vue-echo-laravel';
// window.Pusher = require('pusher-js');
// const EchoInstance = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.MIX_PUSHER_APP_KEY,
//   wsHost: window.location.hostname,
//   cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//   wsPort: 6001,
//   forceTLS: false,
//   disableStats: true,
//   auth: {
//     headers: {
//       Accept:'application/json',
//       csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
//     }
//   },
// });
// Vue.use(VueEcho, EchoInstance);

import VModal from 'vue-js-modal';

Vue.use(VModal);

import Vue2TouchEvents from 'vue2-touch-events';

Vue.use(Vue2TouchEvents, { disableClick: true });

import VueTippy from 'vue-tippy';

Vue.use(VueTippy, {
	delay: [250, 100],
	touchHold: true,
});

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			if (!(el === event.target || el.contains(event.target))) {
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent);
		document.body.addEventListener('contextmenu', el.clickOutsideEvent);
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent);
		document.body.removeEventListener('contextmenu', el.clickOutsideEvent);
	},
});

Vue.config.productionTip = false;
new Vue({
	router: router,
	i18n,
	store,
	render: (h) => h(App),
}).$mount('#app');
