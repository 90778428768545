import api from '@/config/api';

export default {
	state() {
		return {
			notices: null,
		};
	},
	mutations: {
		setNotices(state, payload) {
			state.notices = payload;
		},
		readNotice(state, item) {
			const noticeIndex = state.notices.findIndex((notice) => notice.id === item.id);
			state.notices[noticeIndex].read = true;
		},
	},
	actions: {
		async getNotices({ commit, state }) {
			if (!state.notices) {
				const response = await api.tinn.get('/api/notice/user');
				commit('setNotices', response.data.data.notices);
			}
		},
		async readNotice({ commit }, payload) {
			const response = await api.tinn.put('/api/notice/read', payload);
			commit('readNotice', response.data.data.notice);
		},
	},
	getters: {
		notices(state) {
			const notifications = state.notices;
			notifications.forEach((notice) => {
				const options = {
					day: 'numeric',
					year: 'numeric',
					month: 'long',
				};
				const noticeDate = new Date(notice.created_at);
				notice.created_at = `${noticeDate.toLocaleString('ru-Ru', options)} ${noticeDate.toLocaleTimeString('ru-Ru')}`;
			});
			return notifications;
		},
	},
};
