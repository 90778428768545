import api from '@/config/api';
import i18n from '@/i18n';
import toast, { TYPE } from 'vue-toastification';

class Marketplace {
	constructor() {
		this.toast = null;
	}

	async login(payload, toast) {
		this.toast = toast;
		return await api.marketplace.post(`/login`, payload);
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	async uploadFile(file) {
		const response = await api.marketplace.post(`/file/upload/file`, file);
		return response.data.data.file.id;
	}

	async uploadNFT(nft) {
		const response = await api.marketplace.post(`/file/upload/nft`, nft);
		return response.data.data.file.id;
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	async getMarketplace() {
		const response = await api.marketplace.get(`/marketplace`);
		return response.data.data;
	}

	async getMarketplaceSearch(searchQuery, type, filters) {
		const searchStr = `?search=${searchQuery}`;
		const typeStr = `&type-view=${type}`;
		const auctionStr = filters?.auctions?.length ? `&types_of_auction=[${filters.auctions}]` : '';
		const nftTypeStr = filters?.types?.length ? `&types=[${filters.types}]` : '';
		const categoriesStr = filters?.categories?.length ? `&categories=[${filters.categories}]` : '';
		const minPriceStr = filters?.minPrice && filters?.minPrice !== 0 ? `&min_price=${filters.minPrice}` : '';
		const maxPriceStr = filters?.maxPrice && filters?.maxPrice !== 500000 ? `&max_price=${filters.maxPrice}` : '';
		return await api.marketplace
			.get(`/marketplace/search${searchStr}${typeStr}${auctionStr}${nftTypeStr}${categoriesStr}${minPriceStr}${maxPriceStr}`)
			.then((response) => {
				return response.data.data;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	async getCollections(me = false) {
		const meStr = me ? '?me=true' : '';
		return await api.marketplace
			.get(`/nft-collection${meStr}`)
			.then((response) => {
				return response.data.data.nft_collections;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	async getCollection(id, filters) {
		const params = {};
		if (filters?.auctions?.length) params.types_of_auction = `[${filters?.auctions}]`;
		if (filters?.types?.length) params.types = `[${filters?.types}]`;
		if (filters?.categories?.length) params.categories = `[${filters?.categories}]`;
		if (filters?.minPrice && filters?.minPrice !== 0) params.min_price = filters?.minPrice;
		if (filters?.maxPrice && filters?.maxPrice !== 500000) params.max_price = filters?.maxPrice;
		return await api.marketplace
			.get(`/nft-collection/${id}`, { params })
			.then((response) => {
				response.data.data.nft_collection.nfts = response.data.data.nfts;
				return response.data.data;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	async createCollection(data) {
		return await api.marketplace
			.post(`/nft-collection`, data)
			.then((response) => {
				this.toast(i18n.t('toast.Коллекция создана успешно'), {
					type: TYPE.SUCCESS,
				});
				return response.data.data.nft_collection.id;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	async deleteCollection(id) {
		return await api.marketplace
			.delete(`/nft-collection/${id}`)
			.then(() => {
				this.toast(i18n.t('toast.Коллекция удалена успешно'), {
					type: TYPE.SUCCESS,
				});
				return true;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	async getNFTs() {
		return await api.marketplace
			.get(`/nft`)
			.then((response) => {
				return response.data.data.nft;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	async getNFT(id) {
		return await api.marketplace
			.get(`/nft/${id}`)
			.then((response) => {
				return response.data.data.nft;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	async createNFT(data) {
		return await api.marketplace
			.post(`/nft`, data)
			.then(() => {
				this.toast(i18n.t('toast.NFT создано успешно'), {
					type: TYPE.SUCCESS,
				});
				return true;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	async deleteNFT(id) {
		return await api.marketplace
			.delete(`/nft/${id}`)
			.then(() => {
				this.toast(i18n.t('toast.NFT удалено успешно'), {
					type: TYPE.SUCCESS,
				});
				return true;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	async getFilters() {
		const searchStr = `?search=mmmmmmmmmmmmmm`;
		const typeStr = `&type-view=collections`;
		return await api.marketplace
			.get(`/marketplace/search${searchStr}${typeStr}`)
			.then((response) => {
				return response.data.data;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	async saleNFT(id, payload) {
		return await api.marketplace
			.post(`/nft/${id}/sale`, payload)
			.then(() => {
				this.toast(i18n.t('toast.NFT выставлено на продажу'), {
					type: TYPE.SUCCESS,
				});
				return true;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}

	//////////////////////////////////////////////////////////////////////////////////////////////

	async buyNFT(id) {
		return await api.marketplace
			.post(`/nft/${id}/buy`)
			.then(() => {
				this.toast(i18n.t('toast.NFT куплено успешно'), {
					type: TYPE.SUCCESS,
				});
				return true;
			})
			.catch(() => {
				this.toast(i18n.t('toast.Произошла непредвиденная ошибка'), {
					type: TYPE.ERROR,
				});
			});
	}
}

export default new Marketplace();
