import api from '@/config/api';

export default {
	state() {
		return {
			achievements: null,
			achievement: null,
			achievementUser: null,
		};
	},
	mutations: {
		setAchievements(state, payload) {
			state.achievements = payload;
		},
		setAchievementUser(state, payload) {
			state.achievementUser = payload;
		},
	},
	actions: {
		async getUserAchievements({ commit, state }, id) {
			if (!state.achievements || state.achievementUser !== id) {
				const response = await api.tinn.get(`/api/achievement/user/?user_id=${id}`);
				commit('setAchievements', response.data.data);
				commit('setAchievementUser', id);
			}
		},
	},
};
