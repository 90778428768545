export default {
	state() {
		return {
			ways: null,
			selectedWay: null,
		};
	},
	mutations: {
		setWays(state, payload) {
			state.ways = payload;
		},
		setSelectedWay(state, payload = null) {
			state.selectedWay = payload;
		},
	},
	getters: {
		ways(state) {
			const arr = [];
			for (const [key, value] of Object.entries(state.ways)) {
				const item = {};
				item.title = key;
				item.value = value;
				arr.push(item);
			}

			return arr;
		},
	},
};
