import api from '@/config/api';
import { POSITION, TYPE } from 'vue-toastification';

export default {
	namespaced: true,
	state() {
		return {
			recommended: null,
			popular: [],
			popularData: null,
			activePopularCategory: null,
			popularByCategory: [],
			popularByCategoryData: null,
			videoCategories: null,
			userVideos: null,
			userId: null,
			video: null,
			isVideoDeleted: false,
		};
	},
	mutations: {
		setRecommended(state, payload = null) {
			state.recommended = payload;
		},
		setPopular(state, payload) {
			state.popular.push(...payload);
		},
		setVideoCategories(state, payload) {
			state.videoCategories = payload;
		},
		setCategory(state, payload) {
			state.currentCategory = payload;
		},
		setUserVideos(state, payload = null) {
			state.userVideos = payload;
		},
		setUserId(state, payload) {
			state.userId = payload;
		},
		setVideo(state, payload) {
			state.video = payload;
		},
		setLike(state, payload = null) {
			state.video.video_like = payload;
		},
		setPopularData(state, payload = null) {
			state.popularData = payload;
		},
		setPopularByCategory(state, payload) {
			state.popularByCategory = payload;
		},
		updatePopularByCategory(state, payload) {
			state.popularByCategory.push(...payload);
		},
		setPopularByCategoryData(state, payload) {
			state.popularByCategoryData = payload;
		},
		setActivePopularCategory(state, payload = null) {
			state.activePopularCategory = payload;
		},
		setIsVideoDeleted(state, payload = false) {
			state.isVideoDeleted = payload;
		},
	},
	actions: {
		async getRecommended({ commit, state }, payload) {
			if (!state.recommended || payload) {
				const response = await api.tinn.get(`/api/video/recomended?page=${1}`);
				commit('setRecommended', response.data.data.data);
			}
		},
		async getPopular({ commit, state }, page) {
			if (!state.popularData || (state.popularData && state.popularData.current_page < page)) {
				await api.tinn
					.get(`/api/video/popular?page=${page}`)
					.then((response) => {
						commit('setPopular', response.data.data.data);
						commit('setPopularData', response.data.data);
					})
					.catch(() => {
						this.$toast('Ошибка получения данных', {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
		async getPopularByCategory({ commit, state }, payload) {
			if (!state.activePopularCategory || (state.activePopularCategory && state.activePopularCategory !== payload.category)) {
				await api.tinn
					.get(`/api/video/popular`, {
						params: {
							page: payload.page,
							category_id: payload.category === 'all' ? undefined : payload.category,
						},
					})
					.then((response) => {
						commit('setPopularByCategory', response.data.data.data);
						commit('setPopularByCategoryData', response.data.data);
						commit('setActivePopularCategory', payload.category);
					});
			}
			if (state.activePopularCategory === payload.category && state.popularByCategoryData.current_page < payload.page) {
				await api.tinn
					.get(`/api/video/popular`, {
						params: {
							page: payload.page,
							category_id: payload.category === 'all' ? undefined : payload.category,
						},
					})
					.then((response) => {
						commit('updatePopularByCategory', response.data.data.data);
						commit('setPopularByCategoryData', response.data.data);
					});
			}
		},
		async getVideoCategories({ commit, state }) {
			if (!state.videoCategories) {
				await api.tinn
					.get('/api/video/categories')
					.then((response) => commit('setVideoCategories', response.data.data.categories))
					.catch(() => {
						this._vm.$toast('Ошибка получения категорий', {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
		async getUserVideos({ commit, state }, payload) {
			// if (!state.userVideos || (state.userVideos && state.userId !== payload)) {
			const response = await api.tinn.get('/api/myroom/videos', {
				params: {
					id: payload,
				},
			});
			commit('setUserVideos', response.data.data);
			commit('setUserId', payload);
			// }
		},
		async getVideo({ commit, state }, payload) {
			if (!state.video || (state.video && state.video.video.url !== payload)) {
				commit('setVideo', null);
				await api.tinn
					.get(`/api/video?url=${payload}`)
					.then((response) => commit('setVideo', response.data.data))
					.catch((e) => console.log(e));
			}
		},
		async likeVideo({ commit, state }) {
			await api.tinn
				.post('/api/video/like', {
					video_id: state.video.video.id,
				})
				.then(() => {
					commit('setLike', true);
				});
		},
		async dislikeVideo({ commit, state }) {
			await api.tinn
				.delete('/api/video/like', {
					params: {
						video_id: state.video.video.id,
					},
				})
				.then(() => {
					commit('setLike');
				});
		},
		async deleteVideo({ commit }, uuid) {
			commit('setIsVideoDeleted');
			await api.tinn.delete(`/api/video/${uuid}`).then((response) => {
				if (response.data.errors.message) {
					commit('setIsVideoDeleted');
				} else {
					commit('setIsVideoDeleted', true);
				}
			});
		},
	},
};
