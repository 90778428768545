import api from '@/config/api';
import i18n from '@/i18n';

export default {
	namespaced: true,
	state() {
		return {
			isAuthorized: false,
			authError: false,
			isCodeSended: false,
		};
	},
	mutations: {
		setIsAuthorized(state, payload = false) {
			state.isAuthorized = payload;
		},
		setAuthError(state, payload = false) {
			state.authError = payload;
		},
		setIsCodeSended(state, payload = false) {
			state.isCodeSended = payload;
		},
	},
	actions: {
		async login({ commit }, payload) {
			commit('setIsAuthorized');
			commit('setAuthError');
			await api.auth
				.post(`/api/login`, payload.user)
				.then(async (response) => {
					if (payload.remember) {
						await localStorage.setItem('context', response.data.data.token);
						commit('setIsAuthorized', true);
					} else {
						await sessionStorage.setItem('context', response.data.data.token);
						commit('setIsAuthorized', true);
					}
				})
				.catch(() => {
					commit('setAuthError', i18n.t('validation.Неправильный логин или пароль'));
				});
		},
		logout() {
			localStorage.removeItem('context');
			sessionStorage.removeItem('context');
			window.location.reload();
		},
		async sendCode({ commit }, payload) {
			commit('setIsCodeSended');
			await api.auth
				.post('api/send/code')
				.then(() => {
					commit('setIsCodeSended', true);
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
	getters: {
		authError(state) {
			return state.authError;
		},
		isAuthorized(state) {
			return state.isAuthorized;
		},
	},
};
