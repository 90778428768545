import Vue from 'vue';
import Vuex from 'vuex';
import authorization from './modules/authorization';
import registration from './modules/registration';
import user from './modules/user';
import profiles from './modules/profiles';
import videoRequests from './modules/videoRequests';
import chats from './modules/chats';
import comments from './modules/comments';
import uploadVideo from './modules/uploadVideo';
import wallets from './modules/wallets';
import forum from './modules/forum';
import subscriptions from './modules/subscriptions';
import notices from './modules/notices';
import modals from './modules/modals';
import achievements from './modules/achivements';
import playlists from './modules/playlists';
import settings from './modules/settings';
import videos from './modules/videos';
import invoice from './modules/invoice';
import marketplace from './modules/marketplace';
import { router } from '@/main';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		authorization,
		registration,
		user,
		profiles,
		videoRequests,
		chats,
		comments,
		uploadVideo,
		wallets,
		forum,
		subscriptions,
		notices,
		modals,
		achievements,
		playlists,
		settings,
		videos,
		invoice,
		marketplace,
	},
	state: {
		screenSize: window.innerWidth,
		theme: 'dark',
		userDeleted: null,
	},
	getters: {
		screenSize: (state) => {
			return state.screenSize;
		},
	},
	mutations: {
		setScreenSize(state, payload) {
			state.screenSize = payload.screenSize;
		},
		setTheme(state, payload) {
			state.theme = payload;
		},
		setUserDeleted(state, payload) {
			state.userDeleted = payload;
		},
	},
	actions: {
		setTheme({ commit }, payload) {
			commit('setTheme', payload);
		},
		setUserDeleted({ commit }) {
			commit('setUserDeleted', true);
			router.push('/account-deleted').catch();
		},
	},
});

export default store;
