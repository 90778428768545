import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			chats: [],
			activeChat: {},
			messages: [],
			usersSearchedForChat: [],
		};
	},
	mutations: {
		setChats(state, payload) {
			state.chats = payload;
		},
		setActiveChats(state, payload) {
			state.activeChat = payload;
		},
		setMessages(state, payload) {
			state.messages = payload;
		},
		// setSendMessage(state, payload){
		//     state.messages.unshift({
		//         chat_id: payload.chat_id,
		//         id: payload.id,
		//         message: payload.message,
		//         my_message: true,
		//     });
		// },
		addMessage(state, payload) {
			state.messages.push(payload);
		},
		setUsersSearchedForChat(state, payload) {
			state.usersSearchedForChat = payload;
		},
	},
	actions: {
		async getChats({ commit }) {
			const response = await api.tinn.get('/api/messenger/chats');
			commit('setChats', response.data.data.chats);
		},
		async searchChats({ commit }, payload) {
			const response = await api.tinn.get('/api/messenger/search', {
				params: {
					searchText: payload.searchText,
				},
			});
			// commit('setChats', response.data.data.chats);
			commit('setUsersSearchedForChat', response.data.data.chats);
		},
		async sendMessage(context, payload) {
			await api.tinn.post('/api/messenger/send', payload);
			// context.commit('setSendMessage', response.data.data.messages);
			// await context.dispatch('getMessages', {
			//     params: {
			//         userId: payload.currentUserId,
			//     }
			// });
		},
		async getMessages(context, payload) {
			const response = await api.tinn.get('/api/messenger/chat/messages', {
				params: payload,
			});
			context.commit('setMessages', response.data.data.messages);
		},
	},
	getters: {
		getChats(state) {
			return state.chats;
		},
		getActiveChats(state) {
			return state.activeChat;
		},
		getMessages(state) {
			return state.messages;
		},
		usersSearchedForChat(state) {
			return state.usersSearchedForChat;
		},
	},
};
