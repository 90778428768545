import { POSITION, TYPE } from 'vue-toastification';
import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			videoRequestsCategories: null,
			videoRequestPopular: null,
			videoRequestsByCategories: [],
		};
	},
	mutations: {
		setVideoRequestPopular(state, payload) {
			state.videoRequestPopular = payload;
		},
		setVideoRequestCategories(state, payload) {
			state.videoRequestsCategories = payload;
		},
		setVideoRequestsByCategories(state, payload) {
			state.videoRequestsByCategories.push(payload);
		},
	},
	actions: {
		async getVideoRequestsCategories({ commit, state }) {
			if (!state.videoRequestsCategories) {
				await api.tinn
					.get('/api/video/categories')
					.then((response) => commit('setVideoRequestCategories', response.data.data.categories))
					.catch(() => {
						this._vm.$toast('Ошибка получения категорий', {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
		async getVideoRequestsByCategory({ commit, state }, payload) {
			const cat = state.videoRequestsByCategories.find((category) => category.category_id === payload);

			if (!cat) {
				await api.tinn
					.get('/api/video/request/category', {
						params: {
							category_id: payload,
						},
					})
					.then((response) => {
						const category = {
							category_id: payload,
							requests: response.data.data.video_request_for_user.data,
						};
						commit('setVideoRequestsByCategories', category);
					})
					.catch(() => {
						this._vm.$toast('Ошибка получения заявок по категории', {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
		async getVideoRequestsPopular({ commit, state }) {
			if (!state.videoRequestPopular) {
				await api.tinn
					.get('/api/video/request/popular')
					.then((response) => commit('setVideoRequestPopular', response.data.data.video_request_popular.data))
					.catch(() => {
						this._vm.$toast('Ошибка получения популярных заявок', {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
	},
};
