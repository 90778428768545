<template>
	<div class="app" id="app" v-touch:swipe.bottom="swipeBottomHandler" :class="{ 'dark-theme': this.$store.state.theme === 'dark' }">
		<v-mobile-reload v-if="isShowReload" class="mobile-reload" />

		<router-view @completeVideoRequest="handleVideoRequest"></router-view>

		<v-popup v-if="$store.state.uploadVideo.isShowVideoUpload" :custom-class="$store.state.uploadVideo.uploadStep === 'videoData' ? 'popup_scrolled' : ''">
			<div class="upload" @dragover="dragover" @drop="drop">
				<div class="upload__header" :class="{ step: $store.state.uploadVideo.uploadStep === 'videoData' }">
					<h3>{{ $t('download-video.Загрузка видео') }}</h3>
					<span @click="closeUploadPopup" class="upload__close" :class="{ hidden: $store.state.uploadVideo.uploadStep === 'videoData' }">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14.1872 1.81292L1.81282 14.1873M1.81282 1.81292L14.1872 14.1873L1.81282 1.81292Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</span>
					<icon-back @click="closeUploadPopup" class="upload__close_mobile" :class="{ show: $store.state.uploadVideo.uploadStep === 'videoData' }" />
				</div>

				<div v-if="$store.state.uploadVideo.uploadStep === 'video'" class="drop-area">
					<div class="drop-area__img">
						<svg width="140" height="150" viewBox="0 0 140 150" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M0 103H19V131H121V103H140V141C140 145.971 135.971 150 131 150H121H19H9C4.02944 150 0 145.971 0 141V103Z" />
							<path
								d="M84 47V107C84 110.314 81.3137 113 78 113H62C58.6863 113 56 110.314 56 107V47L35.8317 47.0001C32.3924 47.0001 30.5567 42.9468 32.8253 40.3617L66.9933 1.42609C68.5865 -0.389428 71.413 -0.389426 73.0063 1.4261L107.174 40.3617C109.443 42.9468 107.607 47.0001 104.168 47.0001L84 47Z"
							/>
						</svg>
					</div>
					<div class="drop-area__txt">
						{{ $t('download-video.Выберите или перетащите файл для загрузки') }}
					</div>
				</div>

				<form
					v-if="$store.state.uploadVideo.uploadStep === 'video'"
					class="upload__form"
					:class="{ 'with-progress': $store.state.uploadVideo.uploadStep === 'video' && $store.state.uploadVideo.uploadPercentCompleted }"
					ref="videoform"
				>
					<label class="upload__btn">
						<input v-on:change="uploadVideo" type="file" name="uploading" accept="" id="file" />
						{{ $t('download-video.Выбрать файл') }}
					</label>
				</form>

				<div v-if="$store.state.uploadVideo.uploadStep === 'video' && $store.state.uploadVideo.uploadPercentCompleted" class="upload-progress">
					<div class="video-label"></div>
					<progress :value="$store.state.uploadVideo.uploadPercentCompleted" max="100"></progress>
				</div>
				<div v-if="$store.state.uploadVideo.uploadStep === 'video' && !$store.state.uploadVideo.uploadPercentCompleted" class="upload-progress_hidden" />

				<div v-if="$store.state.uploadVideo.uploadStep === 'video'" class="upload__licence">
					{{ $t('download-video.Добавляя видео, вы принимаете') }}
					<a class="upload__licence-link" href="#" download>{{ $t('download-video.Условия использования и правила сообщества TINN') }}</a>
					<span>.</span>
					<br />
					{{ $t('download-video.Также вы обязуетесь соблюдать авторские права и конфиденциальность данных других пользователей') }}
					<router-link class="upload__licence-link" to="/main"> {{ $t('general.Подробнее') }}...</router-link>
				</div>
				<div
					v-if="$store.state.uploadVideo.uploadStep === 'videoData'"
					class="upload__form step"
					:class="{
						step1: activeComponent === 'v-video-load-checkup',
						step2: activeComponent === 'v-video-load-info',
						step3: activeComponent === 'v-video-load-additions',
						step4: activeComponent === 'v-video-load-public',
					}"
				>
					<v-video-form
						:activeComponent="activeComponent"
						@setIsCheckupPassed="isCheckupPassed = true"
						@setIsInfoPassed="isInfoPassed = true"
						@setIsAdditionPassed="isAdditionsPassed = true"
						@switchComp="switchComp"
						@loaded="isVideoLoadingFinished = true"
					/>
				</div>
			</div>
		</v-popup>

		<popup-video-success v-if="$store.state.uploadVideo.isVideoLoaded && isVideoLoadingFinished" @close="closeLoadedPopup" />
		<popup-video-drafted v-if="$store.state.uploadVideo.isVideoLoaded && !isVideoLoadingFinished" @close="closeLoadedPopup" />
	</div>
</template>

<script>
import i18n from '@/i18n';
import { POSITION, TYPE } from 'vue-toastification';
import PopupVideoSuccess from '@/components/video-load/popup-video-success';
import PopupVideoDrafted from '@/components/video-load/popup-video-drafted';
import IconBack from '@/components/UI/icons/icon-back';

export default {
	name: 'App',
	mounted() {
		this.isShowReload = false;
		const theme = localStorage.getItem('theme');
		if (theme) {
			this.$store.dispatch('setTheme', theme);
		}
		if (this.$store.state.theme === 'dark') {
			document.querySelector('body').style.backgroundColor = '#2C2C2C';
		}
		if (this.$store.state.theme === 'light') {
			document.querySelector('body').style.backgroundColor = '#FFFFFF';
		}
	},
	components: {
		IconBack,
		PopupVideoDrafted,
		PopupVideoSuccess,
		'v-popup': () => import('@/components/v-popup.vue'),
		'v-video-form': () => import('@/components/v-video-form.vue'),
		'v-mobile-reload': () => import('@/components/v-mobile-reload'),
	},
	data() {
		return {
			isShowReload: false,
			isVideoLoadingFinished: false,
			videoName: '',
			requestId: null,
			isDragOver: false,
			activeComponent: 'v-video-load-checkup',
			isCheckupPassed: false,
			isInfoPassed: false,
			isAdditionsPassed: false,
			links: [
				{
					title: i18n.t('start-page.Главная'),
					url: '/main',
					active: true,
				},
				{
					title: i18n.t('search-results.Видео'),
					url: `/profile/`,
					active: false,
				},
				{
					title: i18n.t('search-results.Плейлисты'),
					url: '/main',
					active: false,
				},
			],
			formats: ['video/mpeg', 'video/ogg', 'video/mp4', 'video/webm', 'video/3gp', 'video/flv', 'video/mov', 'video/avi', 'video/wmv', 'video/ts'],
		};
	},
	methods: {
		switchComp(component) {
			if (component === 'v-video-load-checkup') {
				this.activeComponent = 'v-video-load-checkup';
			}
			if (component === 'v-video-load-info' && this.isCheckupPassed) {
				this.activeComponent = 'v-video-load-info';
			}
			if (component === 'v-video-load-additions' && this.isInfoPassed) {
				this.activeComponent = 'v-video-load-additions';
			}
			if (component === 'v-video-load-public' && this.isAdditionsPassed) {
				this.activeComponent = 'v-video-load-public';
			}
		},
		dragover(e) {
			e.preventDefault();
		},
		drop(e) {
			e.preventDefault();
			this.$refs.videoform.file.files = e.dataTransfer.files;
			this.uploadVideo();
		},
		closeUploadPopup() {
			if (this.$store.state.uploadVideo.uploadStep === 'videoData') {
				this.$store.commit('uploadVideo/setUploadPercentCompleted', 0);
				this.$store.commit('uploadVideo/setUploadStep', 'video');
				this.$store.commit('uploadVideo/setIsShowVideoUpload', false);
				this.$store.commit('uploadVideo/setIsVideoLoaded', true);
			} else {
				this.$store.commit('uploadVideo/setIsShowVideoUpload', false);
			}
		},
		async closeLoadedPopup() {
			await this.$store.dispatch('profiles/getUserDataAfterUpdatingChannel', {
				url: this.$store.getters['user/userData'].user_profiles.url,
			});
			await Promise.all([this.$store.dispatch('videos/getUserVideos', this.$store.state.profiles.profile.user_profiles.id), this.$store.dispatch('uploadVideo/closeVideoLoadedPopup')]);
		},
		goToLink(i) {
			this.links.forEach((link) => {
				link.active = false;
			});
			this.links[i].active = true;
			if (this.links[i].title === i18n.t('search-results.Видео')) {
				this.links[i].url = `/profile/${this.userUrl}`;
			}
			if (this.links[i].title === i18n.t('search-results.Плейлисты')) {
				this.links[i].url = `/profile/${this.userUrl}`;
			}
			this.$router.push(this.links[i].url);
		},
		async uploadVideo() {
			if (!this.formats.includes(this.$refs.videoform.file.files[0].type)) {
				this.$toast('Не поддерживаемый формат файла', {
					position: POSITION.TOP_CENTER,
					type: TYPE.ERROR,
				});
				return;
			}

			const data = new FormData();
			data.append('video', this.$refs.videoform.file.files[0]);
			this.isVideoLoadingFinished = false;
			await this.$store.dispatch('uploadVideo/uploadVideo', data);
		},
		swipeBottomHandler() {
			if (window.pageYOffset === 0) {
				this.isShowReload = true;
				location.reload();
			}
		},
		handleVideoRequest(id) {
			this.requestId = id;
			this.$store.commit('uploadVideo/setIsShowVideoUpload', true);
		},
	},
	computed: {
		userUrl() {
			return this.$store.getters['user/userData'].user_profiles.url;
		},
	},
};
</script>

<style scoped lang="scss">
@import '../sass/app';

.app {
	position: relative;

	& .mobile-reload {
		position: fixed;
		top: 30px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 100000;
	}
}

.popup {
	&_scrolled {
		&:deep(.popup-content) {
			overflow-y: auto;
		}
	}

	&:deep(.popup-content) {
		@include breakpoint_MaxHeight($mobile-album) {
			padding: 50px 0;
			overflow-y: auto;
		}
	}
}

.upload {
	width: 100%;
	max-width: 1150px;
	height: 830px;
	padding: 20px 20px 20px 30px;
	background-color: $white;
	border-radius: $b-radius15;
	box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.07);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint_MaxWidth($bp-1280) {
		max-width: 900px;
	}

	@include breakpoint_MaxHeight(840px) {
		width: 100%;
		height: 100%;
		max-width: none;
		border-radius: 0;
		overflow: auto;
		padding: 16px;
	}

	@include breakpoint_MaxWidth($bp-1024) {
		width: 100%;
		height: 100%;
		max-width: none;
		border-radius: 0;
		overflow: auto;
		padding: 16px;
	}

	&__header {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 223px;

		@include breakpoint_MaxWidth($bp-1024) {
			margin-bottom: 0;
			padding: 8px 8px 0 8px;
		}
		@include breakpoint_MaxWidth($mobile-xl) {
			margin-bottom: 30px;
		}

		& h3 {
			@include font-18;
			font-weight: 600;

			@include breakpoint_MaxWidth($mobile-xl) {
				@include font-16;
				font-weight: 600;
			}
		}

		&.step {
			margin-bottom: 0;

			@include breakpoint_MaxWidth($mobile-xl) {
				flex-direction: row-reverse;
				justify-content: start;
				padding: 0;
				margin-bottom: 14px;
			}
		}
	}

	&__close {
		display: inline-flex;
		margin-left: auto;

		cursor: pointer;
		transition: $transition;

		&:hover {
			transform: rotate(180deg);
		}

		& svg path {
			stroke: $black4;
		}

		&.hidden {
			@include breakpoint_MaxWidth($mobile-xl) {
				display: none;
			}
		}

		&_mobile {
			display: none;

			@include breakpoint_MaxWidth($mobile-xl) {
				&.show {
					display: block;
					padding: 5px;
					border: 1px solid $gray10;
					border-radius: 50%;
					width: 28px;
					height: 28px;
					margin-right: 16px;
					cursor: pointer;
				}
			}
		}
	}

	&__form {
		margin-bottom: 155px;

		@include breakpoint_MaxWidth($bp-1024) {
			margin-bottom: auto;
		}
		@include breakpoint_MaxWidth($mobile-xl) {
			margin-bottom: 0;
			width: 100%;
			padding-top: 16px;
		}

		&.step {
			margin-bottom: 0;
			width: 100%;
			height: 100%;
			padding-top: 0;
		}

		&.step1 {
			@include breakpoint_MaxHeight(430px) {
				height: auto;
			}
		}

		&.step2 {
			@include breakpoint_MaxHeight(720px) {
				height: auto;
			}
		}

		&.step3 {
			@include breakpoint_MaxHeight(575px) {
				height: auto;
			}
		}

		&.step4 {
			@include breakpoint_MaxHeight(340px) {
				height: auto;
			}
		}
	}

	&__btn {
		display: block;
		width: 190px;
		padding: 13px;
		@include font-regular16;
		color: $white;
		text-align: center;
		cursor: pointer;
		background-color: $green2;
		border-radius: $b-radius8;
		transition: all 0.15s linear;

		&:hover {
			box-shadow: $hover-shadow;
			background-color: $green-hover;
		}

		& input[type='file'] {
			display: none;
		}

		@include breakpoint_MaxWidth($mobile-xl) {
			width: 100%;
			@include font-14;
		}
	}

	&__licence {
		@include font-14;
		text-align: center;

		@include breakpoint_MaxWidth($bp-1024) {
			width: 90%;
			padding-bottom: 56px;
		}
		@include breakpoint_MaxWidth($mobile-xl) {
			@include font-12;
			padding-bottom: 8px;
		}

		span {
			display: none;

			@include breakpoint_MaxWidth($mobile-xl) {
				display: inline;
			}
		}

		br {
			@include breakpoint_MaxWidth($mobile-xl) {
				display: none;
			}
		}
	}

	&__licence-link {
		color: $green2;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.drop-area {
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint_MaxWidth($bp-1024) {
		margin-top: auto;
	}
	@include breakpoint_MaxWidth($mobile-xl) {
		margin-bottom: auto;
	}

	&__img {
		margin-bottom: 25px;

		@include breakpoint_MaxWidth($mobile-xl) {
			margin-bottom: 32px;
		}

		& svg {
			@include breakpoint_MaxWidth($mobile-xl) {
				width: 90px;
				height: 96px;
			}

			& path:first-child {
				fill: $gray13;
			}

			& path:last-child {
				fill: $green2;
			}
		}
	}

	&__txt {
		margin-bottom: 37px;
		@include font-18;
		font-weight: 600;

		@include breakpoint_MaxWidth($mobile-xl) {
			margin-bottom: 0;
			@include font-14;
			text-align: center;
			width: 70%;
		}
	}
}

.upload-progress {
	@include d-flex-center;
	padding: 20px;

	@include breakpoint_MaxWidth($mobile-xl) {
		width: 100%;
		padding: 5px;
	}

	&_hidden {
		height: 55px;
		min-height: 55px;

		@include breakpoint_MaxWidth($mobile-xl) {
			height: 25px;
			min-height: 25px;
		}
	}

	& progress {
		width: 300px;

		@include breakpoint_MaxWidth($mobile-xl) {
			width: 100%;
		}
	}
}

.video-label {
	margin-bottom: 15px;

	@include font-regular18;
}

.popup-complete {
	display: flex;

	&__nav {
		padding: 30px 20px 20px 10px;
		width: 100%;
		max-width: 393px;

		cursor: default;
		@include settings-content;
		transform: translate(-50%, -50%);

		position: absolute;
		top: 40%;
		left: 20%;

		display: flex;
		justify-content: space-between;
	}

	&__link {
		width: 30%;
		padding-bottom: 10px;
		text-align: center;
		cursor: pointer;
		@include font-regular15;

		&.active {
			border-bottom: 1px solid black;
			font-weight: 700;
		}
	}
}

.video-loaded {
	width: 100%;
	max-width: 645px;
	background: url('@/assets/img/publicated.svg') no-repeat center/cover;

	cursor: default;
	@include settings-content;
	transform: translate(-50%, -50%);

	position: absolute;
	top: 50%;
	left: 50%;

	&__head {
		display: flex;
		justify-content: space-between;
		padding-bottom: 81px;
	}

	&__annotation {
		padding: 20px 0 0 30px;
		@include font-regular16;
		font-weight: 700;
	}

	&__close {
		display: inline-flex;
		padding: 23px;
		cursor: pointer;
		transition: $transition;

		&:hover {
			transform: rotate(90deg);
		}

		& svg {
			cursor: pointer;

			& path {
				stroke: $black;
			}
		}
	}

	&__container {
		display: flex;
		justify-content: space-between;
	}

	&__content {
		padding: 0 0 0px 48px;
	}

	&__title {
		@include font-bold24;
		line-height: 33px;
		max-width: 370px;
		margin-bottom: 24px;
	}

	&__description {
		color: $gray13;
		width: 100%;
		max-width: 340px;
		margin-bottom: 128px;

		p {
			padding: 0;
			margin: 0;
		}
	}
}

.drag {
	background-color: red;
}

progress {
	border-radius: 7px;
	height: 10px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

progress::-webkit-progress-bar {
	background: none;
}

progress::-webkit-progress-value {
	background-color: $green2;
}
</style>
