<template>
	<v-popup :custom-class="'popup--load-complete'">
		<div class="popup-complete">
			<div class="popup-complete__public">
				<div class="video-loaded">
					<div class="video-loaded__head">
						<div class="video-loaded__annotation">{{ $t('download-video.Публикация видео') }}</div>
						<span class="video-loaded__close" @click="$emit('close')">
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.1872 1.81292L1.81282 14.1873M1.81282 1.81292L14.1872 14.1873L1.81282 1.81292Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
							</svg>
						</span>
					</div>
					<div class="video-loaded__container">
						<div class="video-loaded__content">
							<div class="video-loaded__title">{{ $t('download-video.Поздравляем вас') }}</div>
							<div class="video-loaded__description">
								{{ $t('download-video.После завершения конвертации') }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</v-popup>
</template>

<script>
import VPopup from '@/components/v-popup';

export default {
	name: 'popup-video-success',
	components: { VPopup },
};
</script>

<style scoped lang="scss">
@import 'sass/variables';
@import 'sass/mixins';

.popup {
	background: rgba(0, 0, 0, 0.7);
	backdrop-filter: unset;
}

.popup-delete {
	&__close {
		display: flex;

		cursor: pointer;
		transition: $transition;

		position: absolute;
		top: 8px;
		right: 8px;

		@include breakpoint_MaxWidth($mobile-xl) {
			top: -32px;
			right: 0;
			height: 32px;
			width: 32px;
		}

		&:hover {
			transform: rotate(90deg);

			@include breakpoint_MaxWidth($mobile-xl) {
				transform: none;
			}
		}

		::v-deep(svg) {
			cursor: pointer;

			@include breakpoint_MaxWidth($mobile-xl) {
				height: 18px;
				width: 18px;
				margin: auto;
			}

			path {
				stroke: $gray13;

				@include breakpoint_MaxWidth($mobile-xl) {
					stroke: $white;
				}
			}
		}
	}
}

.popup-complete {
	display: flex;

	&__nav {
		padding: 30px 20px 20px 10px;
		width: 100%;
		max-width: 393px;

		cursor: default;
		@include settings-content;
		transform: translate(-50%, -50%);

		position: absolute;
		top: 40%;
		left: 20%;

		display: flex;
		justify-content: space-between;
	}

	&__link {
		width: 30%;
		padding-bottom: 10px;
		text-align: center;
		cursor: pointer;
		@include font-regular15;

		&.active {
			border-bottom: 1px solid black;
			font-weight: 700;
		}
	}
}

.video-loaded {
	width: 100%;
	max-width: 645px;
	background: url('@/assets/img/publicated.svg') no-repeat center/cover;

	cursor: default;
	@include settings-content;
	transform: translate(-50%, -50%);

	position: absolute;
	top: 50%;
	left: 50%;

	&__head {
		display: flex;
		justify-content: space-between;
		padding-bottom: 81px;
	}

	&__annotation {
		padding: 20px 0 0 30px;
		@include font-regular16;
		font-weight: 700;
	}

	&__close {
		display: inline-flex;
		padding: 23px;
		cursor: pointer;
		transition: $transition;

		&:hover {
			transform: rotate(90deg);
		}

		& svg {
			cursor: pointer;

			& path {
				stroke: $black;
			}
		}
	}

	&__container {
		display: flex;
		justify-content: space-between;
	}

	&__content {
		padding: 0 0 0px 48px;
	}

	&__title {
		@include font-bold24;
		line-height: 33px;
		max-width: 370px;
		margin-bottom: 24px;
	}

	&__description {
		color: $gray13;
		width: 100%;
		max-width: 340px;
		margin-bottom: 128px;

		p {
			padding: 0;
			margin: 0;
		}
	}
}
</style>
