window._ = require('lodash');

if (!localStorage.getItem('lang')) {
	localStorage.setItem('lang', 'en');
}

try {
	require('bootstrap');
} catch (e) {
	console.log(e);
}

// function Cookie(name) {
//     let value = "; " + decodeURIComponent(document.cookie);
//     let parts = value.split("; " + name + "=");
//     if (parts.length == 2) return parts.pop().split(";").shift();
// }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from "laravel-echo"
//
// window.Pusher = require('pusher-js');
//
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     //authEndpoint: 'api/broadcasting/auth',
//     key: 'TINNAPP',
//     wsHost: window.location.hostname,
//     cluster: 'mt1',
//     wsPort: 6001,
//     forceTLS: false,
//     disableStats: true,
//     auth: {
//         headers: {
//             Accept:'application/json',
//             csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
//         }
//     },
// });

//window.Echo.options.auth.headers['X-CSRF-TOKEN'] = Cookie('XSRF-TOKEN');
//window.Echo.options.auth.headers['X-Requested-With'] = 'XMLHttpRequest';
