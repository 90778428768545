import { POSITION, TYPE } from 'vue-toastification';
import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			comments: null,
			commentsVideoId: null,
		};
	},
	mutations: {
		setComments(state, payload) {
			state.comments = payload;
		},
		setCommentsVideoId(state, payload) {
			state.commentsVideoId = payload;
		},
	},
	actions: {
		async getComments({ commit, state }, payload) {
			if (!state.comments || (state.comments && state.commentsVideoId !== payload.id) || payload.mode) {
				await api.tinn
					.get('/api/video/comments', {
						params: {
							video_id: payload.id,
						},
					})
					.then((response) => {
						commit('setComments', response.data.data.video_comments);
						commit('setCommentsVideoId', payload.id);
					})
					.catch(() => {
						this._vm.$toast('Ошибка получения комментариев', {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
		async createComment(context, payload) {
			await api.tinn
				.post('/api/video/comments', {
					video_id: payload.video_id,
					parent_id: payload.parent_id,
					content: payload.content,
				})
				.then(
					async () =>
						await context.dispatch('getComments', {
							id: payload.video_id,
							mode: true,
						})
				)
				.catch(() => {
					this._vm.$toast('Ошибка создания комментария', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async addCommentLike(context, payload) {
			await api.tinn
				.post('/api/video/comments/like', {
					comment_id: payload.comment_id,
				})
				.catch(() => {
					this._vm.$toast('Ошибка добавления like', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async removeCommentLike(context, payload) {
			await api.tinn
				.delete('/api/video/comments/like', {
					params: {
						comment_id: payload.comment_id,
					},
				})
				.catch(() => {
					this._vm.$toast('Ошибка удаления like', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
	},
	getters: {
		comments(state) {
			return state.comments;
		},
	},
};
