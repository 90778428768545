import { render, staticRenderFns } from "./popup-video-success.vue?vue&type=template&id=4b6bc805&scoped=true&"
import script from "./popup-video-success.vue?vue&type=script&lang=js&"
export * from "./popup-video-success.vue?vue&type=script&lang=js&"
import style0 from "./popup-video-success.vue?vue&type=style&index=0&id=4b6bc805&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b6bc805",
  null
  
)

export default component.exports