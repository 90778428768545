import Marketplace from '@/lib/services/marketplace';

export default {
	namespaced: true,
	state() {
		return {
			isAuth: false,
		};
	},
	mutations: {
		// setIsAuth(state, payload) {
		// 	state.isAuth = payload;
		// },
	},
	actions: {
		async login({ state }, payload) {
			if (!state.isAuth) {
				await Marketplace.login(payload, this._vm.$toast);
				state.isAuth = true;
			}
		},
	},
	getters: {
		// ways(state) {
		// 	const arr = [];
		//
		// 	return arr;
		// },
	},
};
