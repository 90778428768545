import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			subscriptions: null,
			subscribed: false,
		};
	},
	mutations: {
		setSubscriptions(state, payload) {
			state.subscriptions = payload;
		},
		setSubscribed(state, payload = null) {
			state.subscribed = payload;
		},
	},
	actions: {
		async getSubscriptions({ commit }, url) {
			await api.tinn
				.get(`/api/community/subscriptions/?url=${url}`)
				.then((response) => commit('setSubscriptions', response.data.data.subscriptions))
				.catch((error) => {
					console.log(error.message);
				});
		},
		async updateSubscriptions({ commit }) {
			await api.tinn
				.get('/api/community/subscriptions')
				.then((response) => commit('setSubscriptions', response.data.data))
				.catch((error) => console.log(error.message));
		},
		async subscribeChannel({ commit }, payload) {
			commit('setSubscribed');
			await api.tinn
				.post('/api/community/subscribe', payload)
				.then(() => commit('setSubscribed', true))
				.catch((error) => console.log(error.message));
		},
		async unsubscribeChannel({ commit }, id) {
			commit('setSubscribed', true);
			await api.tinn
				.delete(`/api/community/unsubscribe/?id=${id}`)
				.then(() => commit('setSubscribed', false))
				.catch((error) => console.log(error.message));
		},
	},
};
