import { POSITION, TYPE } from 'vue-toastification';
import api from '@/config/api';
import i18n from '@/i18n';

export default {
	namespaced: true,
	state() {
		return {
			userData: null,
			isAuth: false,
			isDataUpdated: false,
			userAccounts: {},
			statuses: null,
			deleteReasons: null,
			userAuthData: null,
			userTags: [],
			isPassUpdated: false,
			isOldEmailChecked: false,
			isBeganEmailChanging: false,
			// isEmailUpdated: false,
		};
	},
	mutations: {
		setUserData(state, payload) {
			state.userData = payload;
		},
		setIsAuth(state, payload) {
			state.isAuth = payload;
		},
		setIsDataUpdated(state, payload) {
			state.isDataUpdated = payload;
		},
		// setUserAccounts(state, payload){
		//     state.isDataUpdated = payload;
		// },
		setStatuses(state, payload) {
			state.statuses = payload;
		},
		setDeleteReasons(state, payload) {
			state.deleteReasons = payload;
		},
		setUserAuthData(state, payload) {
			state.userAuthData = payload;
		},
		setUserTags(state, payload) {
			state.userTags = payload;
		},
		setIsPassUpdated(state, payload = false) {
			state.isPassUpdated = payload;
		},
		setIsOldEmailChecked(state, payload) {
			state.isOldEmailChecked = payload;
		},
		setIsBeganEmailChanging(state, payload) {
			state.isBeganEmailChanging = payload;
		},
		resetChangingEmailSteps(state) {
			state.isOldEmailChecked = false;
			state.isBeganEmailChanging = false;
			// state.isEmailUpdated = false;
		},
	},
	actions: {
		// async getUserAccounts(context) {
		//     try{
		//         // const response = await axios.get('/api/profile/user');
		//         // if (response.status === 200) {
		//         //     context.commit('setUserData', response.data.data);
		//         //     context.commit('setIsLoadedUserData', true);
		//         // }
		//     } catch (error) {
		//         console.log(error);
		//     }
		// },
		async getUserData({ commit, state }, payload) {
			if (!state.userData || (state.userData && payload)) {
				try {
					const response = await api.tinn.get('/api/profile/user');
					if (response.status === 200) {
						commit('setUserData', response.data.data);
						commit('setIsAuth', true);
					}
				} catch (error) {
					console.log(error);
				}
			}
		},
		async getStatuses({ commit, state }) {
			if (!state.statuses) {
				try {
					const response = await api.tinn.get('/api/profile/statuses');
					if (response.status === 200) {
						commit('setStatuses', response.data.data);
					}
				} catch (e) {
					console.log(e);
				}
			}
		},
		async updateUserData({ commit }, payload) {
			commit('setIsDataUpdated', false);
			return await api.tinn
				.put('/api/profile/user', {
					email: payload.email,
					login: payload.login,
					password: payload.password,
					password_confirmation: payload.password_confirmation,
					password_confirmed: payload.password_confirmed,
					role: payload.role,
					first_name: payload.first_name,
					second_name: payload.second_name,
					phone: payload.phone,
					avatar: payload.avatar,
					language: payload.language,
					url: payload.url,
					date_of_birth: payload.date_of_birth,
					unit_recommended: payload.unit_recommended,
					unit_popular: payload.unit_popular,
					sound: payload.sound,
					text: payload.text,
					likes: payload.likes,
					comments: payload.comments,
					achievements_notices: payload.achievements_notices,
					type_id: payload.type_id,
					subscriptions_id: payload.subscriptions_id,
					playlists_id: payload.playlists_id,
					achievements_privacies_id: payload.achievements_privacies_id,
					gender_id: payload.gender_id,
				})
				.then((response) => {
					commit('setUserData', response.data.data);
					commit('setIsDataUpdated', true);
					return true;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async updateChannelInfo(context, payload) {
			try {
				const response = await api.tinn.put('/api/profile/channel', payload);
				if (response.status === 200) {
					this._vm.$toast(i18n.t('toast.Сохранено'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.SUCCESS,
					});
				}
			} catch (error) {
				this._vm.$toast('Ошибка сохранения', {
					position: POSITION.TOP_CENTER,
					type: TYPE.ERROR,
				});
			}
		},
		async getDeleteReasons({ commit, state }) {
			if (!state.deleteReasons) {
				try {
					const response = await api.auth.get('/api/delete/reasons');
					if (response.status < 300) {
						commit('setDeleteReasons', response.data.data.delete_reasons);
						state.deleteReasons.sort((a, b) => (a.id > b.id ? 1 : -1));
					}
				} catch (error) {
					console.log(error.message);
				}
			}
		},
		async deleteUserAccount(context, payload) {
			try {
				const response = await api.auth.delete('/api/delete/profile', { data: payload });
				if (response.status < 300) {
					localStorage.removeItem('context');
					sessionStorage.removeItem('context');
					window.location.reload();
				}
			} catch (error) {
				console.log(error.message);
			}
		},
		async updateUserBackground({ commit }, payload) {
			try {
				const response = await api.tinn.post('/api/profile/background', payload);
				if (response.status < 300) {
					commit('setUserData', response.data.data);
					commit('setIsDataUpdated', true);
				}
			} catch (error) {
				console.log(error.message);
			}
		},
		async updateUserAvatar({ commit }, payload) {
			try {
				const response = await api.tinn.post('/api/profile/avatar', payload);
				if (response.status < 300) {
					commit('setUserData', response.data.data);
					commit('setIsDataUpdated', true);
				}
			} catch (error) {
				console.log(error.message);
			}
		},
		async getUserAuthData({ commit, state }, payload) {
			if (!state.userAuthData || (state.userAuthData && payload)) {
				await api.auth
					.get('/api/user')
					.then((response) => commit('setUserAuthData', response.data))
					.catch((e) => console.log(e));
			}
		},
		async updateUserTags({ commit }, payload) {
			try {
				commit('setUserTags', payload);
				// const response = await api.tinn.post('/api/', payload);
				// if (response.status < 300) {
				//     commit('setUserHashtags', response.data.data);
				// }
			} catch (error) {
				console.log(error.message);
			}
		},
		async getUserTags({ commit }) {
			let tags = JSON.parse(localStorage.getItem('tags'));
			let tagsSet = Array.from(new Set([...tags, 'qwerty', 'qweasd', 'asdfgh', 'qwe']));
			commit('setUserTags', tagsSet);
		},
		async changeUserPass({ commit }, payload) {
			commit('setIsPassUpdated');

			await api.auth
				.post('/api/user/change-password', payload)
				.then((res) => {
					if (res.data.data) {
						commit('setIsPassUpdated', true);
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		async changeUserEmail({ commit }) {
			commit('setIsOldEmailChecked');

			return await api.auth
				.post('/api/auth/change-email')
				.then((res) => {
					if (res.status < 300) {
						commit('setIsOldEmailChecked', true);
						return true;
					}
					return false;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async verifyOldUserEmail({ commit }, payload) {
			commit('setIsBeganEmailChanging');

			return await api.auth
				.post('/api/auth/verify-old-email', payload)
				.then((res) => {
					if (res.status < 300) {
						commit('setIsBeganEmailChanging', true);
						return true;
					}
					return false;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async verifyNewUserEmail(_, payload) {
			return await api.auth
				.post('/api/auth/verify-new-email', payload)
				.then((res) => {
					return res.status < 300;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
	},
	getters: {
		userAccounts(state) {
			return state.userAccounts;
		},
		userData(state) {
			return state.userData;
		},
		isAuth(state) {
			return state.isAuth;
		},
		statuses(state) {
			return state.statuses;
		},
		deleteReasons(state) {
			return state.deleteReasons;
		},
		chanelInfo(state) {
			return state.userData.channel;
		},
		userAuthData(state) {
			return state.userAuthData;
		},
		userTags(state) {
			return state.userTags;
		},
	},
};
