import { POSITION, TYPE } from 'vue-toastification';
import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			profile: null,
			contentCreatorProfiles: null,
			channel: null,
		};
	},
	mutations: {
		setUserProfile(state, payload) {
			state.profile = payload;
		},
		setContentCreatorProfiles(state, payload) {
			state.contentCreatorProfiles = payload;
		},
		setChannel(state, payload) {
			state.channel = payload;
		},
	},
	actions: {
		async getUserData({ commit, state }, payload) {
			if (!state.profile || (state.profile && state.profile.user_profiles.url !== payload.url)) {
				const response = await api.tinn.get('/api/profile', {
					params: { url: payload.url },
				});
				commit('setUserProfile', response.data.data);
				commit('setChannel', response.data.data.user_profiles.chanel);
			}
		},
		async getUserDataAfterUpdatingChannel({ commit }, payload) {
			const response = await api.tinn.get('/api/profile', {
				params: { url: payload.url },
			});
			commit('setUserProfile', response.data.data);
			commit('setChannel', response.data.data.user_profiles.chanel);
		},
		async getContentCreatorProfiles({ commit }) {
			await api.tinn
				.get('/api/profile/all')
				.then((response) => commit('setContentCreatorProfiles', response.data.data.user_profiles))
				.catch(() => {
					this._vm.$toast('Ошибка получения данных', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
	},
	getters: {
		profile(state) {
			return state.profile;
		},
	},
};
