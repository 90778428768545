<template>
	<v-popup @closePopup="$emit('closeDelete')">
		<div class="popup-video-drafted">
			<span class="popup-video-drafted__close">
				<icon-close-popup @click="$emit('close')" />
			</span>
			<p class="popup-video-drafted__title">
				{{ $t('popup.Черновик сохранен') }}
			</p>
			<p class="popup-video-drafted__subtitle">
				{{ $t('popup.Ваше видео будет доступно только в вашем профиле') }}
			</p>
		</div>
	</v-popup>
</template>

<script>
import VPopup from '@/components/v-popup';
import IconClosePopup from '@/components/UI/icons/icon-close-popup';

export default {
	name: 'popup-video-drafted',
	components: { IconClosePopup, VPopup },
	props: {
		title: String,
		name: String,
	},
};
</script>

<style scoped lang="scss">
@import 'sass/variables';
@import 'sass/mixins';

.popup {
	background: rgba(0, 0, 0, 0.7);
	backdrop-filter: unset;
}

.popup-video-drafted {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: $white;
	padding: 32px;
	border-radius: $b-radius10;
	max-width: 600px;
	min-width: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint_MaxWidth($mobile-xl) {
		width: 100%;
		max-width: none;
		min-width: auto;
		padding: 24px;

		border-radius: 10px 10px 0 0;
		transform: none;

		top: initial;
		bottom: 0;
		left: 0;
	}

	&__close {
		display: flex;

		cursor: pointer;
		transition: $transition;

		position: absolute;
		top: 32px;
		right: 32px;

		@include breakpoint_MaxWidth($mobile-xl) {
			top: -32px;
			right: 0;
			height: 32px;
			width: 32px;
		}

		&:hover {
			transform: rotate(90deg);

			@include breakpoint_MaxWidth($mobile-xl) {
				transform: none;
			}
		}

		::v-deep(svg) {
			cursor: pointer;

			@include breakpoint_MaxWidth($mobile-xl) {
				height: 18px;
				width: 18px;
				margin: auto;
			}

			path {
				stroke: $gray13;

				@include breakpoint_MaxWidth($mobile-xl) {
					stroke: $white;
				}
			}
		}
	}

	&__title {
		@include font-20;
		font-weight: 600;
		margin-bottom: 32px;
		margin-right: auto;

		@include breakpoint_MaxWidth($mobile-xl) {
			@include font-18;
			margin-bottom: 24px;
		}
	}

	&__subtitle {
		@include breakpoint_MaxWidth($mobile-xl) {
			@include font-14;
		}
	}
}
</style>
