import { POSITION, TYPE } from 'vue-toastification';
import api from '@/config/api';
import i18n from '@/i18n';

export default {
	namespaced: true,
	state() {
		return {
			userVideoRequests: null,
			videoRequestTypes: [
				{ name: i18n.t('create-request.Приватный'), value: 1 },
				{ name: i18n.t('create-request.Публичный'), value: 2 },
			],
			recievedVideoRequests: null,
		};
	},
	mutations: {
		setUserVideoRequests(state, payload) {
			state.userVideoRequests = payload;
		},
		setRecievedVideoRequests(state, payload) {
			state.recievedVideoRequests = payload;
		},
	},
	actions: {
		async getReceivedVideoRequests({ commit, state }, payload) {
			// if (!state.recievedVideoRequests || payload) {
			await api.tinn
				.get('/api/video/request/received')
				.then((response) => commit('setRecievedVideoRequests', response.data.data.video_request_for_user))
				.catch(() => {
					this._vm.$toast('Ошибка получения заявок на видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
			// }
		},
		async getUserVideoRequests({ commit, state }, payload) {
			// if (!state.userVideoRequests || payload) {
			await api.tinn
				.get('/api/video/request/user')
				.then((response) => commit('setUserVideoRequests', response.data.data.video_request_for_user))
				.catch(() => {
					this._vm.$toast('Ошибка получения заявок на видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
			// }
		},
		async updateVideoRequest(context, payload) {
			await api.tinn
				.put('/api/video/request', {
					video_id: payload.video_id,
					took_user_id: payload.took_user_id,
					status_id: payload.status_id,
					type_id: payload.type_id,
					theme: payload.theme,
					description: payload.description,
					amount: payload.amount,
					deadline: payload.deadline,
					file: payload.file,
				})
				.then(async () => await context.dispatch('getUserVideoRequests', true))
				.catch(() => {
					this._vm.$toast('Ошибка обновления заявки на видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async updateRecieveVideoRequest(context, payload) {
			await api.tinn
				.put('/api/video/request', {
					video_id: payload.video_id,
					took_user_id: payload.took_user_id,
					status_id: payload.status_id,
					type_id: payload.type_id,
					theme: payload.theme,
					description: payload.description,
					amount: payload.amount,
					deadline: payload.deadline,
					file: payload.file,
				})
				.then(async () => await context.dispatch('getReceivedVideoRequests', true))
				.catch(() => {
					this._vm.$toast('Ошибка обновления заявки на видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async cancelVideoRequest(context, payload) {
			await api.tinn
				.put('/api/video/request/cancelled', payload)
				.then(async () => await context.dispatch('getUserVideoRequests', true))
				.catch(() => {
					this._vm.$toast('Ошибка отмены заявки на видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async rejectVideoRequest(context, payload) {
			await api.tinn
				.put('/api/video/request/rejected', payload)
				.then(async () => await context.dispatch('getReceivedVideoRequests', true))
				.catch(() => {
					this._vm.$toast('Ошибка отмены заявки на видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async acceptVideoRequest(context, payload) {
			await api.tinn
				.put('/api/video/request/in-progress', payload)
				.then(async () => await context.dispatch('getReceivedVideoRequests', true))
				.catch(() => {
					this._vm.$toast('Ошибка принятия заявки на видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async completeVideoRequest(context, payload) {
			await api.tinn
				.post('/api/video/request/completed', payload)
				.then(async () => await context.dispatch('getReceivedVideoRequests', true))
				.catch(() => {
					this._vm.$toast('Ошибка загрузки видео', {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
	},
};
