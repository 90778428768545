import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			transactions: null,
			reports: null,
		};
	},
	mutations: {
		setTransactions(state, payload) {
			state.transactions = payload;
		},
		setReports(state, payload) {
			state.reports = payload;
		},
	},
	actions: {
		async getTransactions({ commit, state }) {
			if (!state.transactions) {
				await api.tinn.get('/api/payment/history').then((response) => {
					commit('setTransactions', response.data.data);
				});
			}
		},
		async getReports({ commit, state }) {
			if (!state.reports) {
				const response = await api.tinn.get('/api/profile/reports');
				if (response.status === 200) {
					commit('setReports', response.data.data.user_reports);
				}
			}
		},
	},
};
