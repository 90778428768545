import { router } from '@/main';
import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			uploadPercentCompleted: 0,
			isShowVideoUpload: false,
			uploadStep: 'video',
			videoId: null,
			updateMain: 0, // temp for update main page after upload new video
			isVideoLoaded: false,
			videoCovers: null,
			loadedCover: null,
			isVideoUpdated: false,
			isCoverSetted: false,
		};
	},
	mutations: {
		setIsVideoLoaded(state, payload) {
			state.isVideoLoaded = payload;
		},
		setIsShowVideoUpload(state, payload) {
			state.isShowVideoUpload = payload;
		},
		setUploadStep(state, payload) {
			state.uploadStep = payload;
		},
		setUploadPercentCompleted(state, payload) {
			state.uploadPercentCompleted = payload;
		},
		setVideoId(state, payload = null) {
			state.videoId = payload;
		},
		setVideoCovers(state, payload = null) {
			state.videoCovers = payload;
		},
		setIsVideoUpdated(state, payload = null) {
			state.isVideoUpdated = payload;
		},
		setLoadedCover(state, payload = null) {
			state.loadedCover = payload;
		},
		setVideoCover(state, payload = null) {
			state.isCoverSetted = payload;
		},
	},
	actions: {
		closeVideoLoadedPopup({ commit }) {
			commit('setIsVideoLoaded', false);
		},
		async uploadVideo({ commit }, payload) {
			commit('setVideoId');

			await api.vcs
				.post('/api/convert', payload, {
					onUploadProgress: (e) => {
						commit('setUploadPercentCompleted', Math.round((e.loaded / e.total) * 100));
					},
				})
				.then((response) => {
					commit('setVideoId', response.data.data.video.video_id);
					commit('setUploadStep', 'videoData');
				});
		},
		async getCovers({ commit }, id) {
			commit('setVideoCovers');
			await api.tinn.get(`/api/video/covers/${id}`).then((response) => {
				commit('setVideoCovers', response.data.data.covers.reverse());
			});
		},
		async loadCover({ commit }, payload) {
			commit('setLoadedCover');

			await api.vcs.post('/api/cover', payload).then((response) => {
				commit('setLoadedCover', response.data.data);
			});
		},
		async setCover({ commit }, payload) {
			commit('setVideoCover');

			await api.tinn.put(`/api/video/cover/${payload.cover_id}`, payload.cover).then(() => {
				commit('setVideoCover', true);
			});
		},
		async updateVideo({ commit }, payload) {
			commit('setIsVideoUpdated');
			await api.tinn.put('/api/video/', payload).then(() => {
				commit('setIsVideoUpdated', true);
			});
		},
		async finishUploadVideo(context) {
			context.commit('setUploadStep', 'video');
			context.commit('setIsShowVideoUpload', false);
			context.commit('setUploadPercentCompleted', null);
			context.commit('setIsVideoLoaded', true);
			if (router.currentRoute.name !== 'Main') {
				await router.push({ name: 'Main' });
			} else {
				context.state.updateMain++;
			}
		},
	},
	getters: {
		updateMain(state) {
			return state.updateMain;
		},
	},
};
