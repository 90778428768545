import { router } from '../../main';
import api from '@/config/api';
import i18n from '@/i18n';

export default {
	namespaced: true,
	state() {
		return {
			errorMessage: '',
			errorMessagesBack: null,
			codeError: false,
			emailError: false,
		};
	},
	mutations: {
		setCurrentStep(state, payload) {
			state.currentStep = payload;
		},
		setCodeError(state, payload = false) {
			state.codeError = payload;
		},
		setEmailError(state, payload = false) {
			state.emailError = payload;
		},
		setEmailErrorMessageBack(state) {
			state.emailError = false;
		},
		setCodeErrorMessageBack(state) {
			state.codeError = false;
		},
	},
	actions: {
		async registerStepOne(context, payload) {
			context.commit('setEmailError');
			context.commit('setCodeError');

			await api.auth
				.post('/api/register', {
					email: payload.email,
					password: payload.password,
					password_confirmation: payload.password_confirmation,
					// role: payload.role,
					sex: payload.sex,
					language: payload.language,
					code: payload.code,
				})
				.then(async (response) => {
					await localStorage.setItem('context', response.data.data.token);
					context.commit('setCurrentStep', 2);
					await router.push({ name: 'Mail__confirmation' });
				})
				.catch((e) => {
					if (e.response.data.data.code && e.response.data.data.code[0]) {
						context.commit('setCodeError', i18n.t('auth.Неверный код приглашения'));
					}
					if (e.response.data.data.email && e.response.data.data.email[0]) {
						context.commit('setEmailError', i18n.t('auth.Данный почтовый адрес уже используется'));
					}
				});
		},
	},
};
