const Page404 = () => import('@/pages/Page404.vue');
const Login = () => import('@/pages/Login.vue');
const Mail__confirmation = () => import('@/pages/Mail__confirmation.vue');
const Sign_Up = () => import('@/pages/Sign_Up.vue');
const Start__page = () => import('@/pages/Start__page.vue');
const Main = () => import('@/pages/Main.vue');
const Popular = () => import('@/pages/Popular.vue');
const Forum = () => import('@/pages/Forum.vue');
const Opened__request = () => import('@/pages/Opened__request.vue');
const MyRequests = () => import('@/pages/MyRequests.vue');
const Create__request = () => import('@/pages/Create__request.vue');
const Search__results = () => import('@/pages/Search__results.vue');
const Opened__video = () => import('@/pages/Opened__video.vue');
const Profile = () => import('@/pages/Profile.vue');
const vProfileMain = () => import('@/components/profile_components/v-profile-main.vue');
const vPlaylist = () => import('@/components/profile_components/v-playlist.vue');
const Profile__creator = () => import('@/pages/Profile__creator.vue');
const Settings = () => import('@/pages/Settings.vue');
const Settings__common = () => import('@/components/settings_components/Settings__common.vue');
const Financials = () => import('@/components/settings_components/Financials.vue');
const Wallet = () => import('@/components/settings_components/Wallet.vue');
const Requests = () => import('@/components/settings_components/Requests.vue');
const Chats = () => import('@/components/settings_components/Chats.vue');
const Statistics = () => import('@/components/settings_components/Statistics.vue');
const Donates = () => import('@/components/settings_components/Donates');
const Myroom = () => import('@/pages/My_room.vue');
const OpenedRequestCreator = () => import('@/pages/OpenedRequestCreator');
const AccountDeleted = () => import('@/pages/AccountDeleted');
const Marketplace = () => import('@/pages/Marketplace');
const Market = () => import('@/pages/Market');
const Drops = () => import('@/pages/Drops');
const Drop = () => import('@/pages/Drop');
const NFTCollection = () => import('@/pages/NFTCollection');
const NFT = () => import('@/pages/NFT');
const Marketplace__SearchResult = () => import('@/pages/Marketplace__SearchResult');
const MarketCollections = () => import('@/pages/MarketCollections');
const MarketNft = () => import('@/pages/MarketNft');
const CreateCollection = () => import('@/pages/CreateCollection');
const CreateNFT = () => import('@/pages/CreateNFT');
const SellNFT = () => import('@/pages/SellNFT');
const ForgotPass = () => import('@/pages/auth/passwordReset/Index.vue');
const BalanceReplenishment = () => import('@/pages/BalanceReplenishment.vue');
const Invoice = () => import('@/pages/Invoice.vue');
const Invoice2 = () => import('@/pages/Invoice2.vue');

export const routes = [
	{
		name: 'Login',
		path: '/login',
		component: Login,
	},
	{
		name: 'ForgotPass',
		path: '/reset-password',
		component: ForgotPass,
	},
	{
		name: 'Mail__confirmation',
		path: '/email-confirm',
		component: Mail__confirmation,
	},
	{
		name: 'Sign_Up',
		path: '/sign-up',
		component: Sign_Up,
	},

	{
		name: 'Start__page',
		path: '/',
		component: Start__page,
		redirect: { name: 'Main' },
		children: [
			{
				name: 'Main',
				path: '/main',
				component: Main,
			},
			{
				name: 'Popular',
				path: '/popular',
				component: Popular,
			},
			{
				name: 'Forum',
				path: '/forum',
				component: Forum,
			},
			{
				name: 'Opened__request',
				path: '/opened-request/:id',
				component: Opened__request,
			},
			{
				name: 'MyRequests',
				path: '/my-requests',
				component: MyRequests,
			},
			{
				name: 'Create__request',
				path: '/create-request',
				component: Create__request,
			},
			{
				name: 'Search__results',
				path: '/search-results/:query',
				component: Search__results,
			},
			{
				name: 'MyRoom',
				path: '/room',
				component: Myroom,
			},
			{
				name: 'Marketplace',
				path: '/marketplace',
				component: Marketplace,
				redirect: '/market',
				children: [
					{
						name: 'Market',
						path: '/market',
						component: Market,
					},
					{
						name: 'Drops',
						path: '/drops',
						component: Drops,
					},
				],
			},
			{
				name: 'Marketplace__SearchResult',
				path: '/market-search',
				component: Marketplace__SearchResult,
			},
			{
				name: 'NFT',
				path: '/nft/:id',
				component: NFT,
			},
			{
				name: 'Drop',
				path: '/drop/:id',
				component: Drop,
			},
		],
	},
	{
		name: 'Opened__video',
		path: '/video/:url',
		component: Opened__video,
	},
	{
		name: 'Profile',
		path: '/profile/:url',
		component: Profile,
		redirect: { name: 'v-profile-main' },
		children: [
			{
				name: 'v-profile-main',
				path: '/profile/:url/main',
				component: vProfileMain,
			},
			{
				name: 'v-playlist',
				path: '/playlist/:id',
				component: vPlaylist,
			},
		],
	},
	{
		name: 'Settings',
		path: '/settings',
		component: Settings,
		children: [
			{
				name: 'Settings__common',
				path: '/common-settings',
				component: Settings__common,
			},
			{
				name: 'Financials',
				path: '/financials',
				component: Financials,
			},
			{
				name: 'Wallet',
				path: '/wallet',
				component: Wallet,
			},
			{
				name: 'Requests',
				path: '/requests',
				component: Requests,
			},
			{
				name: 'Chats',
				path: '/chats',
				component: Chats,
			},
			{
				name: 'Statistics',
				path: '/statistics',
				component: Statistics,
			},
			{
				name: 'Donates',
				path: '/donates',
				component: Donates,
			},
		],
	},
	{
		name: 'Profile__creator',
		path: '/profile-creator',
		component: Profile__creator,
	},
	{
		name: 'OpenedRequestCreator',
		path: '/opened-request-creator/:requestId',
		component: OpenedRequestCreator,
	},
	{
		name: 'CreateCollection',
		path: '/create-collection',
		component: CreateCollection,
	},
	{
		name: 'NFTCollection',
		path: '/nft-collection/:id',
		component: NFTCollection,
	},
	{
		name: 'CreateNFT',
		path: '/create-nft',
		component: CreateNFT,
	},
	{
		name: 'SellNFT',
		path: '/sell-nft',
		component: SellNFT,
	},
	{
		name: 'BalanceReplenishment',
		path: '/balance',
		component: BalanceReplenishment,
	},
	{
		name: 'Invoice',
		path: '/invoice/:link',
		component: Invoice,
	},
	{
		name: 'Invoice2',
		path: '/invoice/:link/:way',
		component: Invoice2,
	},
	{
		name: 'AccountDeleted',
		path: '/account-deleted',
		component: AccountDeleted,
	},
	{
		name: 'Page404',
		path: '*',
		component: Page404,
	},
];
