<template>
	<div class="popup" :class="customClass">
		<div @click.self="close" class="popup-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'v-popup',
	props: {
		customClass: {
			type: String,
			default: '',
		},
	},
	methods: {
		close() {
			this.$emit('closePopup');
		},
	},
	mounted() {
		document.querySelector('body').style.overflow = 'hidden';
	},
	destroyed() {
		document.querySelector('body').style.overflow = 'auto';
	},
};
</script>

<style scoped lang="scss">
@import '../../sass/variables';
@import '../../sass/mixins';

.popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	//background: url("@/assets/img/login-bg.webp") no-repeat center/cover;
	background: #bff1e7;
	backdrop-filter: blur(200px);
	cursor: pointer;
	z-index: 1000;

	&.popup--delete_account,
	&.popup--new_video_sources,
	&.popup--unsubscribed,
	&.popup--send-request,
	&.popup--open-achievement,
	&.popup--request,
	&.popup--bought-nft,
	&.popup--delete_playlist,
	&.popup--delete_video {
		background: rgba($black, 0.85);
	}

	&.popup--load-complete {
		background: rgba($black, 0.7);
		backdrop-filter: none;
	}

	&.popup--notifications {
		width: 100%;
		max-width: 480px;
		overflow: hidden;

		cursor: default;
		background: $white;
		border-radius: $b-radius10;
		box-shadow: $box-shadow;
		backdrop-filter: none;

		top: 25px;
		right: 120px;
		left: unset;
		bottom: unset;
	}

	&.popup--small {
		background: rgba($black, 0.85);
		backdrop-filter: none;
	}
}

.popup-content {
	width: 100%;
	height: 100%;

	position: relative;
}
</style>
