import axios from 'axios';
import store from '@/store';
import { router } from '@/main';

//Основной tinn
const tinn = axios.create({ baseURL: process.env.VUE_APP_ROOT_API });
tinn.interceptors.request.use((config) => {
	config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	config.headers.common['Accept'] = 'application/json';
	let token = localStorage.getItem('context');
	let sessionToken = sessionStorage.getItem('context');
	if (token && token.length && !sessionToken) {
		config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
	} else if (sessionToken && sessionToken.length) {
		config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('context')}`;
	}

	return config;
});

tinn.defaults.validateStatus = (status) => status < 501;
tinn.interceptors.response.use((response) => {
	if (response.status > 401) {
		console.error('Error');
	}
	if (response.status === 401) {
		localStorage.removeItem('context');
		sessionStorage.removeItem('context');
		router.push('/login').catch();
	}
	if (response.status === 429) {
		localStorage.removeItem('context');
		sessionStorage.removeItem('context');
		router.push('/login').catch();
	}
	if (response.data.errors[0] === 'Account was deleted') {
		store.dispatch('setUserDeleted').catch();
	}
	return response;
});

//Сервис авторизации
const auth = axios.create({ baseURL: process.env.VUE_APP_AUTH_API });

auth.interceptors.request.use((config) => {
	config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	config.headers.common['Accept'] = 'application/json';
	let token = localStorage.getItem('context');
	let sessionToken = sessionStorage.getItem('context');
	if (token && token.length && !sessionToken) {
		config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
	} else if (sessionToken && sessionToken.length) {
		config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('context')}`;
	}

	return config;
});

auth.interceptors.response.use((response) => {
	if (response.status > 401) {
		console.error('Error');
		return response;
	}
	return response;
});

//Сервис загрузки и конвертации видео
const vcs = axios.create({ baseURL: process.env.VUE_APP_VCS_API });
vcs.interceptors.request.use((config) => {
	config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	config.headers.common['Accept'] = 'application/json';
	let token = localStorage.getItem('context');
	let sessionToken = sessionStorage.getItem('context');
	if (token && token.length && !sessionToken) {
		config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
	} else if (sessionToken && sessionToken.length) {
		config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('context')}`;
	}

	return config;
});

vcs.defaults.validateStatus = (status) => status < 501;

//Сервис заявок
const requests = axios.create({ baseURL: process.env.VUE_APP_REQUESTS_API });
requests.interceptors.request.use((config) => {
	config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	config.headers.common['Accept'] = 'application/json';
	config.headers.common['LangSet'] = 'ru';
	let token = localStorage.getItem('context');
	let sessionToken = sessionStorage.getItem('context');
	if (token && token.length && !sessionToken) {
		config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
	} else if (sessionToken && sessionToken.length) {
		config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('context')}`;
	}

	return config;
});

requests.defaults.validateStatus = (status) => status < 501;

//Сервис чатов
const chat = axios.create({ baseURL: process.env.VUE_APP_CHAT_API });

chat.interceptors.request.use((config) => {
	config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	config.headers.common['Accept'] = 'application/json';
	let token = localStorage.getItem('context');
	let sessionToken = sessionStorage.getItem('context');
	if (token && token.length && !sessionToken) {
		config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
	} else if (sessionToken && sessionToken.length) {
		config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('context')}`;
	}

	return config;
});

chat.defaults.validateStatus = (status) => status < 501;

//Сервис платежей
const pay = axios.create({ baseURL: process.env.VUE_APP_PAY_API });

pay.interceptors.request.use((config) => {
	config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	config.headers.common['Accept'] = 'application/json';
	let token = localStorage.getItem('context');
	let sessionToken = sessionStorage.getItem('context');
	if (token && token.length && !sessionToken) {
		config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
	} else if (sessionToken && sessionToken.length) {
		config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('context')}`;
	}

	return config;
});

pay.defaults.validateStatus = (status) => status < 501;

//Сервис маркетплейса
const marketplace = axios.create({ baseURL: process.env.VUE_APP_MARKETPLACE_API });

marketplace.interceptors.request.use((config) => {
	config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
	config.headers.common['Accept'] = 'application/json';
	let token = localStorage.getItem('context');
	let sessionToken = sessionStorage.getItem('context');
	if (token && token.length && !sessionToken) {
		config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('context')}`;
	} else if (sessionToken && sessionToken.length) {
		config.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('context')}`;
	}

	return config;
});

marketplace.defaults.validateStatus = (status) => status >= 200 && status < 300;
marketplace.interceptors.response.use(
	(response) => {
		if (response.status > 401) {
			console.error('Error');
		}
		if (response.status === 401) {
			localStorage.removeItem('context');
			sessionStorage.removeItem('context');
			router.push('/login').catch();
		}
		if (response.status === 429) {
			localStorage.removeItem('context');
			sessionStorage.removeItem('context');
			router.push('/login').catch();
		}
		if (response.data.errors[0] === 'Account was deleted') {
			store.dispatch('setUserDeleted').catch();
		}
		return response;
	}
	//how to toast?
	// (error) => {
	// 	if (!error.response) {
	// 		Toast(i18n.t('toast.Произошла ошибка. Проверьте ваше подключение к сети'), {
	// 			type: TYPE.ERROR,
	// 		});
	// 		return;
	// 	}
	// 	return Promise.reject(error);
	// }
);

const api = () => {
	return { tinn, auth, vcs, requests, chat, pay, marketplace };
};

export default api();
