export default {
	namespaced: true,
	state() {
		return {
			isShowModalPay: {
				name: '',
				status: false,
			},
			isShowModalSale: false,
			isShowModalPlaylist: false,
		};
	},
	mutations: {
		setIsShowModalPay(state, payload) {
			state.isShowModalPay = payload;
		},
		setIsShowModalSale(state, payload) {
			state.isShowModalSale = payload;
		},
		setIsShowModalPlaylist(state, payload) {
			state.isShowModalPlaylist = payload;
		},
	},
	actions: {},
	getters: {
		isShowModalPay(state) {
			return state.isShowModalPay;
		},
		isShowModalSale(state) {
			return state.isShowModalSale;
		},
	},
};
