import { POSITION, TYPE } from 'vue-toastification';
import api from '@/config/api';
import i18n from '@/i18n';

export default {
	namespaced: true,
	state() {
		return {
			playlists: null,
			playlistUserId: null,
			userPlaylists: null,
			isPlaylistCreated: false,
			isVideoAdded: false,
			isVideoDeleted: false,
			isPlaylistDeleted: false,
		};
	},
	mutations: {
		setPlaylists(state, payload = null) {
			state.playlists = payload;
		},
		setPlaylistUserId(state, payload) {
			state.playlistUserId = payload;
		},
		setUserPlaylists(state, payload) {
			state.userPlaylists = payload;
		},
		setIsPlayListCreated(state, payload = null) {
			state.isPlaylistCreated = payload;
		},
		setIsVideoAdded(state, payload = null) {
			state.isVideoAdded = payload;
		},
		setIsVideoDeleted(state, payload = null) {
			state.isVideoDeleted = payload;
		},
		setIsPlaylistDeleted(state, payload = null) {
			state.isPlaylistDeleted = payload;
		},
	},
	actions: {
		async getPlaylists({ commit, state }, payload) {
			if (!state.playlists || (state.playlists && state.playlistUserId !== payload.userId)) {
				commit('setPlaylists');
				await api.tinn
					.get('/api/playlist/', {
						params: {
							id: payload.userId,
						},
					})
					.then((response) => {
						commit('setPlaylists', response.data.data.playlist);
						commit('setPlaylistUserId', payload.userId);
					})
					.catch(() => {
						this._vm.$toast(i18n.t('toast.Ошибка получения плейлиста'), {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
		async getUserPlaylists({ commit, state }, payload) {
			if (!state.userPlaylists || (state.userPlaylists && payload)) {
				await api.tinn
					.get('/api/playlist/user')
					.then((response) => commit('setUserPlaylists', response.data.data.playlists))
					.catch(() => {
						this._vm.$toast(i18n.t('toast.Ошибка получения плейлистов'), {
							position: POSITION.TOP_CENTER,
							type: TYPE.ERROR,
						});
					});
			}
		},
		async addVideoToPlaylist({ commit }, payload) {
			commit('setIsVideoAdded');
			await api.tinn
				.post('/api/playlist/element', payload)
				.then(async () => {
					this._vm.$toast(i18n.t('toast.Добавлено в плейлист'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.SUCCESS,
					});
					commit('setIsVideoAdded', true);
				})
				.catch(() => {
					this._vm.$toast(i18n.t('toast.Ошибка добавления в плейлист'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async createPlaylist({ commit }, payload) {
			commit('setIsPlayListCreated');
			await api.tinn
				.post('/api/playlist', payload)
				.then(() => {
					this._vm.$toast(i18n.t('toast.Плейлист создан'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.SUCCESS,
					});
					commit('setIsPlayListCreated', true);
				})
				.catch(() => {
					this._vm.$toast(i18n.t('toast.Ошибка создания плейлиста'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async deletePlaylist({ commit }, payload) {
			commit('setIsPlaylistDeleted');
			await api.tinn
				.delete('/api/playlist', {
					params: {
						id: payload.id,
					},
				})
				.then(() => {
					this._vm.$toast(i18n.t('toast.Плейлист удалён'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.SUCCESS,
					});
					commit('setIsPlaylistDeleted', true);
				})
				.catch(() => {
					this._vm.$toast(i18n.t('toast.Ошибка при удалении плейлиста'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
		async deleteVideoFromPlaylist({ commit }, payload) {
			commit('setIsVideoDeleted');
			await api.tinn
				.delete('/api/playlist/element', {
					params: {
						id: payload.id,
					},
				})
				.then(() => {
					this._vm.$toast(i18n.t('toast.Видео удалено'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.SUCCESS,
					});
					commit('setIsVideoDeleted', true);
				})
				.catch(() => {
					this._vm.$toast(i18n.t('toast.Ошибка при удалении видео'), {
						position: POSITION.TOP_CENTER,
						type: TYPE.ERROR,
					});
				});
		},
	},
};
