import api from '@/config/api';

export default {
	namespaced: true,
	state() {
		return {
			isDonatSend: false,
			isVideoSale: false,
			isVideoBought: false,
			buyError: false,
			isAchivBought: false,
		};
	},
	mutations: {
		setIsDonatSend(state, payload = false) {
			state.isDonatSend = payload;
		},
		setIsVideoSale(state, payload = false) {
			state.isVideoSale = payload;
		},
		setIsVideoBought(state, payload = false) {
			state.isVideoBought = payload;
		},
		setBuyError(state, payload = false) {
			state.buyError = payload;
		},
		setIsAchivBought(state, payload = false) {
			state.isAchivBought = payload;
		},
	},
	actions: {
		async payDonat({ commit, state, rootState }, payload) {
			commit('setIsDonatSend');
			await api.tinn.post(`/api/payment/donat`, payload).then(() => {
				commit('setIsDonatSend', true);
				rootState.user.userData.user_profiles.balance = rootState.user.userData.user_profiles.balance - payload.amount;
			});
		},
		async sellVideo({ commit }, payload) {
			commit('setIsVideoSale');
			await api.tinn.post('/api/payment/sell', payload).then(() => {
				commit('setIsVideoSale', true);
			});
		},
		async buyVideo({ commit }, id) {
			commit('setIsVideoBought');
			commit('setBuyError');

			await api.tinn.post('/api/payment/buy', { video_id: id }).then((res) => {
				if (res.status) {
					commit('setIsVideoBought', true);
				} else {
					commit('setBuyError', res.data.errors.success);
				}
			});
		},
		async buyAchiev({ commit }, payload) {
			commit('setIsAchivBought');

			await api.tinn
				.post('/api/pay/achievement', payload)
				.then(() => {
					commit('setIsAchivBought', true);
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
